import gettext from 'airborne/gettext';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import dataset from 'airborne/bookings2/helpers/dataset';
import range from 'lodash/range';


function getPages(current, total, pagerSize) {
    if (total <= pagerSize) {
        // 1 2 3 4 5 6 7 8 9
        return range(1, total + 1);
    }
    else if (current <= Math.ceil(pagerSize / 2)) {
        // 1 2 3 _4_ 5 6 7 ... 27
        // 1 2 3 4 _5_ 6 7 ... 27
        return [...range(1, pagerSize-1), null, total];
    }
    else if (current >= total - Math.floor(pagerSize / 2)) {
        // 1 ... 21 22 _23_ 24 25 26 27
        // 1 ... 21 22 23 _24_ 25 26 27
        return [1, null, ...range(total - pagerSize + 3, total + 1)];
    }
    else {
        // 1 ... 4 5 _6_ 7 8 ... 27
        // 1 ... 5 6 _7_ 8 9 ... 27
        // 1 ... 19 20 _21_ 22 23 ... 27
        // 1 ... 20 21 _22_ 23 24 ... 27
        let leftPad = Math.floor((pagerSize - 5) / 2);  // how many pages to display by left side of current
        let rightPad = Math.ceil((pagerSize - 5) / 2);  // how many pages to display by right side of current
        return [1, null, ...range(current - leftPad, current + rightPad + 1), null, total];
    }
}


export default class Pager extends React.Component {
    static propTypes = {
        page: PropTypes.number.isRequired,
        totalPages: PropTypes.number.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    handleChange = (event)=> {
        event.preventDefault();

        const {page} = dataset(event.target);
        if (page) {
            this.props.onChange(Number(page));
            window.scrollTo(0, 0);
        }
    };

    renderPrevious(page) {
        const className = classnames('c-pagination__nav', {
            'c-pagination__nav--inactive': page === 1,
        });
        return <li className={className} data-page={page-1}>« {gettext('Previous')}</li>;
    }

    renderNext(page, total) {
        const className = classnames('c-pagination__nav', {
            'c-pagination__nav--inactive': page === total,
        });
        return <li className={className} data-page={page+1}>{gettext('Next')} »</li>;
    }

    renderPage(page, active, idx) {
        const className = classnames('c-pagination__page', {
            'c-pagination__page--active': active,
            'c-pagination__page--inactive': !Boolean(page),
        });
        return (
            <li key={idx} data-page={page} className={className}>
                {page || '...'}
            </li>
        );
    }

    renderPages(current, total) {
        const pagerSize = 9;
        const pages = getPages(current, total, pagerSize);

        return (
            <ul className="c-pagination" onClick={this.handleChange}>
                {this.renderPrevious(current)}
                {pages.map((page, idx) => this.renderPage(page, page === current, idx))}
                {this.renderNext(current, total)}
            </ul>
        );
    }

    render() {
        const {page, totalPages} = this.props;

        if (!totalPages || totalPages === 1) {
            return null;
        }

        return (
            <div className="c-pagination__wrapper">
                <ul className="c-pagination">
                    {this.renderPages(page, totalPages)}
                </ul>
            </div>
        );
    }
}
