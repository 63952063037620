import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getRatesToShow} from 'airborne/store/modules/search_cars/selectors';
import Rate from './Rate';
import {getAdvertisingStatus} from 'airborne/store/modules/homepage/selectors/homepage';
import CarsDestinationSearchRefreshModal from 'airborne/cars/search/CarsDestinationSearchRefreshModal';

import settings from 'airborne/settings';
import {carsLabeling} from 'airborne/search2/helpers/hyatt';

const ADS_LIST = [null, '76486', '76490', '76491', '76492', '76493', '76494', '76495', '76496', '76497'];
const ADS_STEP = 3;

function mapStateToProps(state) {
    return {
        labeling: carsLabeling(state),
        rates: getRatesToShow(state),
        advertisingStatus: getAdvertisingStatus(state)
    };
}

@connect(mapStateToProps)
export default class Rates extends React.Component {
    static propTypes = {
        rates: PropTypes.arrayOf(PropTypes.object).isRequired,
        advertisingStatus: PropTypes.bool,
        labeling: PropTypes.object,
    };

    state = {showRefreshButtonPrompt: false};

    componentDidMount() {
        this.startCarsRefreshTimer();
    }

    componentWillUnmount() {
        this.clearCarsRefreshTimer();
    }

    timeout = null;
    CARS_REFRESH_TIMER = 20 * 60_000; // 20 min

    clearCarsRefreshTimer = () => {
        clearTimeout(this.timeout);
    }

    startCarsRefreshTimer = () => {
        this.clearCarsRefreshTimer();

        // timeout will come from BE in seconds, so we need to multiply this value by 1000
        const carsRefreshTimer = settings['CARS_RATE_KEY_TTL'] * 1000 || this.CARS_REFRESH_TIMER;
        this.timer = setTimeout(() => this.setState({showRefreshButtonPrompt: true}), carsRefreshTimer);
    };


    showAds = (index) => {
        const {advertisingStatus} = this.props;
        const rateIndex =(((index)/3) ^ 0) === (index/3) ? index : null;
        if (!rateIndex || !advertisingStatus) return null;

        return (<div className="c-content__inline-a"> <broadstreet-zone zone-id={ADS_LIST[rateIndex/ADS_STEP]} /></div>);
    }

    render() {
        const {labeling} = this.props;
        return (
            <div>
                {this.props.rates.map((rate, index) => {
                    return (<Fragment key={rate.rateId}>
                        <Rate {...rate} labeling={labeling} />
                        {this.showAds(index + 1)}
                    </Fragment>);
                })}

                <CarsDestinationSearchRefreshModal show={this.state.showRefreshButtonPrompt}/>
            </div>
        );
    }
}
